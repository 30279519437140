import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useScrollDirection } from '../../utility/scrollDirection.hook'
import MobileHeader from './components/MobileHeader'

import logo from './assets/logo.svg'

const homeNav = [
    {text: 'Ecosystem', url: {pathname: '/', hash: 'ecosystem'}},
    {text: 'Projects', url: {pathname: '/', hash: 'projects'}},
    {text: 'UBX', url: {pathname: '/', hash: 'ubx'}},
    {text: 'About', url: {pathname: '/', hash: 'about'}},
    {text: 'Guides', url: '/guides'}   
]

const desktopNav = [
    ...homeNav,
    {text: 'Blog', url: '/blog'}
]

const nav = [
    {text: 'Home', url: '/'},
    {secondLevel: homeNav},
    {text: 'UBX Page', url: '/ubx'},
    {text: 'Staking', url: '/staking'},
    {text: 'Blog', url: '/blog'},
    {text: 'Guides', url: '/guides'}   
]

const Header = () => {
    const scrollDirection = useScrollDirection()
    return (
        <>
            <header className={
                twMerge(`
                    hidden
                    py-4 
                    px-16 
                    lg:flex 
                    items-center 
                    bg-black 
                    bg-opacity-20 
                    fixed
                    w-full 
                    z-10
                    backdrop-blur-[10px]
                    transition-[top]`, 
                    scrollDirection === 'down' ? '-top-full' : 'top-0')}>
                <Link to='/'>
                    <img src={logo} alt="UBIX Network"/>
                </Link>
                <nav className='mx-auto'>
                    <ul className='flex'>
                        {desktopNav.map((item) => 
                            <li key={item.text} className='p-[10px] mr-3 xl:mr-12 last:mr-0'>
                                <Link to={item.url} className='text-white'>{item.text}</Link>
                            </li>)}
                    </ul>
                </nav>
                {/* <Search/> */}
                <a href='https://ubikiri.com/auth/login-registration?returnUrl=%2F' target='_blank'>
                <button className='
                    py-[11px]
                    px-8
                    rounded-lg 
                    border-1 
                    border-white
                    text-white
                    font-manrope
                    font-extrabold
                    ml-4'>
                    
                    Übikiri Login
                    
                </button>
                </a>
            </header>
            <MobileHeader nav={nav} show={scrollDirection !== 'down'}/>
        </>  
    )
} 

export default Header